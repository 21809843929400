/*
 * @Descripttion:调用后端订单接口的函数
 * @Author: congz
 * @Date: 2020-06-14 14:24:12
 * @LastEditors: congz
 * @LastEditTime: 2020-08-12 20:03:36
 */

import axios from 'axios'
import request from '@/utils/request.js'

// 创建订单
// const postOrder = form =>
//   axios.post('/api/v1/orders', form).then(res => res.data)
const postOrder = form => request({
	url: '/front_order',
	method: 'post',
	data: form
})

// 读取订单
// const listOrders = (user_id, type, start, limit) =>
//   axios
//     .get(`/api/v1/user/${user_id}/orders`, {
//       params: { type, start, limit }
//     })
//     .then(res => res.data)
const listOrders = (query) => request({
	url: '/front_order',
	method: 'get',
	params: query ?? {}
})

// 读取订单详情
const showOrder = (id) => request({
	url: '/front_order/' + id,
	method: 'get'
})

// 取消订单
const cancelOrder = id => request({
	url: '/front_order/' + id,
	method: 'delete'
})

export { postOrder, listOrders, showOrder, cancelOrder }
